body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.calendarCont {
  border: 2px solid #015fcc;
  border-radius: 6px;
}

.calendarCont,
.calendarCont .DayPicker,
.calendarCont .DayPicker .DayPicker-Month {
  width: 100%;
}

.mapDiv {
  height: 470px;
}

.editorContainer {
  height: 500px;
}

.priceField {
  font-size: 26px;
  font-weight: 600;
}


/* Blogs Page CSS */
.blog-post {
  border: 1px solid #eee;
  border-radius: 5px;
  display: block;
  padding: 0 15px 20px;
  height: 100%;
  overflow: hidden;
  transition: all .5s;
}

.blog-post:hover {
  box-shadow: 3px 3px 20px #ccc;
}

.blog-post .img-wrapper {
  margin: 0 -15px;
}

.img-wrapper .blog-image {
  width: 100%;
  height: 210px;
  object-fit: cover;
  display: block;
}

.blog-label {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;
}

.blog-title {
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  color: #0e101a;
  margin-top: 10px;
}

.blog-date {
  color: #333954;
  margin-top: 10px;
}

/* Blogs Details Page CSS */
.blog-details-banner {
  background-color: #f8f9fe;
}

.blog-details-content {
  max-width: 750px;
  color: #333954;
}

.blog-details-content .blog-heading {
  color: #0e101a;
  line-height: normal;
  font-weight: 600;
}

.blog-details-content .img-wrapper img {
  margin: 0 auto;
}

.blog-details-content .blog-list {
  list-style-type: none;
  padding: 0;
}

.blog-details-content .blog-list li {
  margin: 15px 0;
  padding-left: 20px;
  position: relative;
}

.blog-details-content .blog-list li:before {
  content: '';
  height: 8px;
  width: 8px;
  background-color: #015fcc;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 9px;
  left: 0;
}

.blog-details-content .video-wrapper iframe {
  margin: 0 auto;
  max-width: 560px;
  width: 100%;
}

.blog-details-content a {
  color: #4a6ee0;
  text-decoration: none;
}

.blogs .ck.ck-editor__editable {
  min-height: 400px;
}

.ck.ck-balloon-panel {
  z-index: 1111 !important;
}

/* Create & Edit Blog Page */
.uploaded-img-wrapper {
  border: 2px dotted #ccc;
  max-width: 500px;
  min-height: 230px;
  border-radius: 5px;
  margin: 0 auto;
  overflow: hidden;
}
.uploaded-img-wrapper img, .uploaded-img-wrapper iframe, .uploaded-img-wrapper video {
  display: block;
  max-height: 230px;
  height: 100%;
  width: 100%;
  min-height: 230px;
  object-fit: cover;
}

/* loader css */

.loader-wrapper {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1111;
}

.loader-spiner {
  z-index: 11;
}

/* blog advertise media title */
.blog-advertise-media div {
  text-transform: capitalize;
}

/* for createsubscription plan page */
.ck.ck-editor__editable {
  min-height: 250px;
}